import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    fontFamily: 'NeueEinstellungBold',
    fontSize: '14px',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'NeueEinstellungBold',
    fontSize: '14px',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#1B40A7',
  color: '#fff',
  height: '40px',
  boxShadow: '0px 0px 0px rgba(0,0,0,0)',
  fontFamily: 'NeueEinstellungBold',
  fontSize: '12px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#1B40A7',
  },
});

const EditGoals = () => {
  const [techGoals, setTechGoals] = useState([]);

  useEffect(() => {
    fetchTechGoals();
  }, []);

  const fetchTechGoals = async () => {
    try {
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-goals');
      setTechGoals(response.data);
    } catch (err) {
      console.error('Error fetching tech goals:', err);
    }
  };

  const handleGoalChange = (techId, field, value) => {
    setTechGoals(prevGoals => {
      return prevGoals.map(goal => {
        if (goal.tech_id === techId) {
          return { ...goal, [field]: value };
        }
        return goal;
      });
    });
  };

  const handleSaveGoal = async (techId) => {
    try {
      const goal = techGoals.find(goal => goal.tech_id === techId);
      await axios.put(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-goals/${techId}`, {
        yearlyGoal: goal.yearly_goal,
        monthlyGoal: goal.monthly_goal,
      });
      // Show success message or perform any other necessary actions
    } catch (err) {
      console.error('Error saving tech goal:', err);
      // Show error message or perform any other necessary actions
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom style={{ marginBottom: '16px', fontFamily: 'NeueEinstellungBold' }}>
        Edit Goals
      </Typography>
      {techGoals.map(goal => (
        <Grid container key={goal.tech_id} spacing={2} alignItems="center" style={{ marginBottom: '16px' }}>
          <Grid item xs={12} sm={3}>
            <StyledTextField
              label="Tech Name"
              value={goal.tech_name}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledTextField
              label="Yearly Goal"
              value={goal.yearly_goal}
              onChange={(e) => handleGoalChange(goal.tech_id, 'yearly_goal', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledTextField
              label="Monthly Goal"
              value={goal.monthly_goal}
              onChange={(e) => handleGoalChange(goal.tech_id, 'monthly_goal', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledButton variant="contained" onClick={() => handleSaveGoal(goal.tech_id)}>
              Save
            </StyledButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default EditGoals;
