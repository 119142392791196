// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import TechnicianDashboard from './views/TechnicianDashboard';
import AdminDashboard from './views/AdminDashboard';
import DataDashboard from './views/DataDashboard';
import CoolerDetails from './views/CoolerDetails';
import CoolerHierarchy from './views/CoolerHierarchy';
import PMOwners from './views/PMOwners';
import NotFound from './views/NotFound';
import Settings from './views/Settings';
import Sidebar from './views/Sidebar';
import BigMap from './views/BigMap';
import TechnicianData from './views/TechnicianData';
import FileUploadPage from './views/FileUploadPage';
import TechPmReport from './views/TechPmReport';
import ChildOfDetails from './views/ChildOfDetails';
import AssignTechs from './views/AssignTechs';
import UpdateRecords from './views/UpdateRecords';
import GroupListView from './views/GroupListView';
import ChildOfListView from './views/ChildOfListView';
import CoolersListView from './views/CoolersListView';
import PredictiveMaintenance from './views/PredictiveMaintenance';
import AddCooler from './views/AddCooler';
import Login from './views/Login';
import ServicePage from './views/ServicePage';
import { AppBar, Toolbar, Typography, IconButton, Container, useMediaQuery, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css';
import { CircularProgress } from '@mui/material';
import KommunicateChat from './views/chat';
import UploadCoolers from './views/UploadCoolers';

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [userRole, setUserRole] = useState(null); // Add state for user role
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    checkSession
  } = useAuth0();

  const namespace = 'https://app.shursolution.com';
  const role = user && user[`${namespace}/roles`];
  const TOKEN_RENEWAL_INTERVAL = 30 * 60 * 1000; // 30 minutes

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/get-user-role', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ userId: user.sub }) // user.sub is the user ID in Auth0
          });

          if (response.ok) {
            const data = await response.json();
            console.log(data)
            setUserRole(data.roles); // Set the user role
            setShow(data.roles.includes('Admin')); // Adjust role check as needed
          }
        } catch (error) {
          console.error("Error fetching user role", error);
        }
      }
    };

    fetchUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useEffect(() => {
    const silentAuth = async () => {
      try {
        await checkSession();
      } catch (error) {
        if (error.error === "login_required") {
          loginWithRedirect();
        }
      }
    };

    silentAuth();

    const renewToken = async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently();
        } catch (error) {
          console.error("Error renewing Auth0 token:", error);
        }
      }
    };

    const intervalId = setInterval(renewToken, TOKEN_RENEWAL_INTERVAL);

    return () => clearInterval(intervalId);
  }, [checkSession, loginWithRedirect, getAccessTokenSilently, isAuthenticated]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ color: '#1B40A7' }} />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  const Dashboard = show === true ? AdminDashboard : TechnicianDashboard;

  return (
    <div style={{ backgroundColor: "rgb(245,245,245)", minHeight: '100vh' }}>
      <Router>
        <AppBar position="static" style={{ backgroundColor: "#1B40A7", boxShadow: 'none', position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              style={{ zIndex: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              pointerEvents: 'none'
            }}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit', pointerEvents: 'auto' }}>
                <Typography
                  variant={isMobile ? "subtitle2" : "h6"}
                  style={{ fontFamily: "Akira" }}
                >
                  FreshPet PM
                </Typography>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
        <Container style={{ marginTop: '2rem' }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/technician" element={<TechnicianDashboard />} />
            <Route path="/datadashboard" element={<DataDashboard />} />
            <Route path="/cooler-details/:serialNumber" element={<CoolerDetails />} />
            <Route path="/add-cooler" element={<AddCooler />} />
            <Route path="/tech-report" element={<TechPmReport />} />
            <Route path="/newcoolers" element={<ChildOfListView />} />
            <Route path="/file-upload" element={<FileUploadPage />} />
            <Route path="/technician-data/:name" element={<TechnicianData />} />
            <Route path="/coolers/:childOf" element={<CoolersListView />} />
            <Route path="/childof-details/:childOf" element={<ChildOfDetails />} />
            <Route path="/manage-pm-owners" element={<PMOwners />} />
            <Route path="/assign-techs" element={<AssignTechs />} />
            <Route path="/big-map" element={<BigMap />} />
            <Route path="/cooler-records" element={<CoolerHierarchy />} />
            <Route path="/service/:coolerId" element={<ServicePage />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/upload-coolers" element={<UploadCoolers />} />
            <Route path="/predictive-maintenance" element={<PredictiveMaintenance />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
        {/* {userRole && userRole.includes('Admin') && <KommunicateChat />}  Conditionally render based on user role */}
      </Router>
    </div>
  );
};

const Auth0ProviderWithRedirect = ({ children }) => {
  const domain = "dev-lzhsywd3ggnqo1p0.us.auth0.com";
  const clientId = "cWCoW5MjmBxUfP8UUll9QcdLbp9KBdF2";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

export default () => (
  <Auth0ProviderWithRedirect>
    <App />
  </Auth0ProviderWithRedirect>
);
