import React, { useState } from 'react';
import axios from 'axios';
import { Button, Box, Typography, Container, Grid, Tabs, Tab, FormControlLabel, Switch  } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditGoals from './EditGoals';
import BackButton from '../components/BackButton';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#1B40A7',
  },
});

const StyledTab = styled(Tab)({
  fontFamily: 'NeueEinstellungBold',
  fontSize: '14px',
  color: '#000',
  '&.Mui-selected': {
    color: '#1B40A7',
  },
});

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showPlaceholders, setShowPlaceholders] = useState(false);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const geocodeAddresses = async () => {
        try {
            // Make a request to the server endpoint for geocoding
            await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/geocode');
            alert('Geocoding process started. This might take a while.');
        } catch (error) {
            console.error('Error triggering geocoding:', error);
            alert('Failed to start geocoding process.');
        }
    };

  const resetDontAskAgainPreference = () => {
    // ...
  };

  const sendEmailReport = async () => {
    // ...
  };

  const buttonStyle = {
    backgroundColor: '#1B40A7',
    color: '#fff',
    height: '50px',
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
    fontFamily: 'NeueEinstellungBold',
    fontSize: '10px',
    margin: '8px',
    width: 'calc(50% - 16px)',
    borderRadius: '8px',
  };

  return (
    <Container>
      <BackButton />
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold', marginTop: '2rem' }}>
        Settings
      </Typography>
      <StyledTabs value={selectedTab} onChange={handleTabChange} style={{ marginBottom: '2.5rem' }}>
        <StyledTab label="Edit Goals" />
        <StyledTab label="Advanced" />

      </StyledTabs>
      {selectedTab === 1 && (
  <Grid container spacing={2} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom style={{ fontFamily: 'NeueEinstellungNormal', fontSize: '14px', lineHeight: 1.5, padding: '0 16px' }}>
        You should generally not need to do anything here, these are for testing.
      </Typography>
    </Grid>
    <Grid item xs={12} sm={8}>
      <Button variant="contained" style={buttonStyle} onClick={geocodeAddresses}>
        Start Geocoding
      </Button>
    </Grid>
    <Grid item xs={12} sm={8}>
      <Button variant="contained" style={buttonStyle} onClick={resetDontAskAgainPreference}>
        Reset 'Don't Ask Again' Preference
      </Button>
    </Grid>
    <Grid item xs={12} sm={8}>
      <Button variant="contained" style={buttonStyle} onClick={sendEmailReport}>
        Send Daily Email Report
      </Button>
    </Grid>
    <Grid item xs={12} sm={8}>
     <FormControlLabel
       control={<Switch checked={showPlaceholders} onChange={(e) => setShowPlaceholders(e.target.checked)} />}
       label="Show Cooler Placeholders"
     />
   </Grid>
  </Grid>

)}
      {selectedTab === 0 && (
        <EditGoals />
      )}
    </Container>
  );
};

export default Settings;
