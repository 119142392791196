import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

const NotFound = () => {
  return (
    <div>
      <Typography variant="h4">Page Not Found</Typography>
      <Button variant="contained" component={Link} to="/dashboard">Go to Dashboard</Button>
    </div>
  );
};

export default NotFound;
