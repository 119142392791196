import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import arrow icon

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  // Keep the button styles the same, as you want constant width and height
  const buttonStyle = {
    width: '250px', // Fixed width
    height: '50px', // Fixed height
    backgroundColor: '#1B40A7',
    fontFamily: 'NeueEinstellungBold',
    borderRadius: '15px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    margin: '0 auto', // This will center the button in the flex container
  };

  return (
    <Grid
      container
      style={{
        height: '100vh',
        background: 'linear-gradient(18deg, #1e65b3 0%, #ffffff 29%)'
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} style={{ textAlign: 'center', margin: '1vh 0' }}>
        <Typography
          variant="h4"
          style={{
            color: '#000000',
            fontFamily: 'Akira',
            marginBottom: '20rem'
          }}
        >
          Login
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={() => loginWithRedirect()}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '1rem', marginBottom: '1rem', bottom: '10%', textAlign: 'center' }}>
        <img src="/shurlogo.png" alt="Logo" style={{ maxWidth: '40%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

export default Login;
