import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Container,
  Card,
  LinearProgress,
  CardContent,
} from '@mui/material';
import { VictoryArea, VictoryLine, VictoryBar, VictoryStack, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';
import { VictoryPie, VictoryTooltip } from 'victory';


const AdminDashboard = () => {
  const { user } = useAuth0();
  const [coolers, setCoolers] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mtdServicedCoolersCount, setMtdServicedCoolersCount] = useState(0);
  const [technicianProgress, setTechnicianProgress] = useState([]);
  const [servicedCoolersCount, setServicedCoolersCount] = useState(0);
  const TOTAL_COOLERS = 2400;
  const TOTAL_COOLERS1 = 200;
  const animationDuration = 2000;
  const chartContainerStyle = {
  width: '100%', // Use 100% to fill the container or set a specific size
  maxWidth: '350px', // Set a maximum width for the charts
  margin: '0 auto', // This centers the chart in the Paper if it's smaller
};
  const buttonStyle = {
    backgroundColor: '#1B40A7',
    color: '#fff',
    height: '50px',
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
    fontFamily: 'NeueEinstellungBold',
    margin: '8px',
    width: 'calc(50% - 16px)',
    borderRadius: '8px'
  };
  useEffect(() => {
  const fetchYtdPmEvents = async () => {
    try {
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/ytd-pm-events');
      const events = response.data;
      const totalServicedCoolers = events.length;
      setServicedCoolersCount(totalServicedCoolers);
    } catch (err) {
      console.error('Error fetching YTD PM events', err);
    }
  };

  fetchYtdPmEvents();
}, []);

useEffect(() => {
  const fetchTechProgress = async () => {
    try {
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-progress');
      console.log(response.data); // Check the structure of the response
      setTechnicianProgress(response.data.data); // Assuming the API responds with { data: [...] }
    } catch (err) {
      console.error('Error fetching technician progress', err);
    }
  };

  fetchTechProgress();
}, []);


useEffect(() => {
  const fetchMtdPmEvents = async () => {
    try {
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/mtd-pm-events');
      const events = response.data;

      const processedData = events.reduce((acc, event) => {
        const date = new Date(event.pm_date).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});
      const chartData = Object.entries(processedData).map(([date, count]) => ({
        date, count
      }));

      setServiceData(chartData);

      // Calculate MTD serviced coolers count from the processed data
      const mtdTotalServicedCoolers = chartData.reduce((total, data) => total + data.count, 0);
      setMtdServicedCoolersCount(mtdTotalServicedCoolers);

    } catch (err) {
      console.error('Error fetching MTD PM events', err);
    }
  };

  fetchMtdPmEvents();
}, []);

  const paperStyle = {
    padding: '1rem',
    borderRadius: '20px',
    //minHeight: '500px', // Adjust this value based on your requirement
    fontFamily: 'NeueEinstellungBold',
  };

  const totalCoolersByLocation = coolers.reduce((acc, cooler) => {
    acc[cooler.location] = (acc[cooler.location] || 0) + 1;
    return acc;
  }, {});

  const locationData = Object.entries(totalCoolersByLocation).map(([location, count]) => ({
    location,
    count,
  }));
  const servicedCoolersPercentage = Math.round((servicedCoolersCount / TOTAL_COOLERS) * 100);
  const servicedCoolersPercentage1 = Math.round((mtdServicedCoolersCount / TOTAL_COOLERS1) * 100);

// Then update the pieChartData1 to reflect MTD data
const pieChartData1 = [
  { x: "Serviced", y: mtdServicedCoolersCount },
  { x: "Unserviced", y: TOTAL_COOLERS1 - mtdServicedCoolersCount },
];

  const assignedCoolersCount = coolers.reduce((acc, cooler) => cooler.AssignedTechnicianName ? acc + 1 : acc, 0);
  const totalCoolersCount = coolers.length;

  const pieChartData = [
   { x: "Serviced", y: servicedCoolersCount },
   { x: "Unserviced", y: TOTAL_COOLERS - servicedCoolersCount },
 ];

  const cardStyle = {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'left',
   padding: '1rem',
   marginBottom: '5rem',
   borderRadius: '20px',
   //boxShadow: '0px 6px 18px rgba(0,0,0,0.1)',
   backgroundColor: 'white',
 };

 const areaStyle = {
   data: {
     fill: "#1B40A7", // Area color
     fillOpacity: 0.7, // Semi-opaque
   },
 };

 // Define the BlueLinearProgress outside of the ProgressBar component
const BlueLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: '30px',
  borderRadius: '8px',
  backgroundColor: '#CCCCCC',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    // Change the bar color based on the value
    backgroundColor: value > 100 ? '#FF0000' : '#1B40A7',
  },
}));

const ProgressBar = ({ name, mtdProgress, ytdProgress }) => {
  // Convert progress values to numbers
  const mtdValue = parseFloat(mtdProgress);
  const ytdValue = parseFloat(ytdProgress);
  // Function to determine if value exceeds 100%
  const valueExceeds = (value) => value > 100;
  return (
    <Box>
      <Typography variant="h6" color="black" fontFamily="Grifter" marginTop="20px">
        <Link to={`/technician-data/${name}`}>{name}</Link>
      </Typography>
      <Typography variant="body2" color="black" fontFamily="Grifter" marginTop="10px">YTD Progress: {ytdValue}% {valueExceeds(ytdValue) && "(Exceeded)"}</Typography>
      <BlueLinearProgress variant="determinate" value={Math.min(ytdValue, 100)} />
      <Typography variant="body2" color="black" fontFamily="Grifter" marginTop="10px">MTD Progress: {mtdValue}% {valueExceeds(mtdValue) && "(Exceeded)"}</Typography>
      <BlueLinearProgress variant="determinate" value={Math.min(mtdValue, 100)} />
    </Box>
  );
};


 return (
  <Container>
    <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar>
        <Typography variant="h8" style={{ paddingLeft: '0', flexGrow: 1, color: '#333', fontFamily: "Akira" }}>
          Welcome Back, {user.name.split(" ")[0]}
        </Typography>
      </Toolbar>
    </AppBar>
    <Grid container justifyContent="center" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
       <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>

         <Button variant="contained" style={buttonStyle} component={Link} to="/assign-techs">
           Assign Techs
         </Button>
         <Button variant="contained" style={buttonStyle} component={Link} to="/cooler-records">
           View Coolers
         </Button>

       </Grid>
     </Grid>

    {/* Charts Section, integrated with your data */}
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4}>
        <Paper elevation={0} style={{ ...paperStyle, maxWidth: '500px', margin: '0 auto' }}>
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold' }}>
            YTD Goal: {servicedCoolersPercentage}%
          </Typography>
          <VictoryPie
            data={pieChartData}
            colorScale={["#1B40A7", "#cccccc"]}
            labels={() => ''}
            width={500}
            height={500}
            style={{
              parent: {
                maxWidth: '500px',
              }
            }}
          />
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold' }}>
            {servicedCoolersCount} of {TOTAL_COOLERS} Coolers Serviced
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Paper elevation={0} style={{ ...paperStyle, maxWidth: '500px', margin: '0 auto' }}>
        <Typography variant="h8" gutterBottom style={{ fontSize: 12, fontFamily: 'NeueEinstellungBold' }}>
MTD Goal: {servicedCoolersPercentage1}%
</Typography>
<VictoryPie
data={pieChartData1}
            colorScale={["#1B40A7", "#cccccc"]}
            labels={() => ''}
            width={500}
            height={500}
            style={{
              parent: {
                maxWidth: '500px',
              }
            }}
          />
          <Typography variant="h8" gutterBottom style={{ fontSize: 12, fontFamily: 'NeueEinstellungBold' }}>
  {mtdServicedCoolersCount} of {TOTAL_COOLERS1} Coolers Serviced
</Typography>
        </Paper>
      </Grid>
      {!isMobile && (
      <Grid item xs={6} sm={4}>
        <Paper elevation={0} style={{ ...paperStyle, maxWidth: '500px', margin: '0 auto' }}>
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold'}}>
            Coolers Serviced in Last 30 Days
          </Typography>
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={50}
            padding={{ top: 20, bottom: 50, left: 50, right: 25 }}
            width={500}
            height={500}
            style={{
              parent: {
                maxWidth: '500px',
              }
            }}
            animate={{
              duration: animationDuration,
              onLoad: { duration: animationDuration }
            }}
          >
            <VictoryAxis
              tickValues={serviceData.map((sd) => sd.date)}
              tickFormat={serviceData.map((sd) => sd.date)}
              style={{ tickLabels: { angle: 45, fontSize: 8, verticalAnchor: 'middle', textAnchor: 'start' } }}
            />
            <VictoryAxis dependentAxis tickFormat={(x) => `${x}`} />
            <VictoryArea
              data={serviceData}
              x="date"
              y="count"
              style={areaStyle}
              interpolation="natural"
            />
          </VictoryChart>
        </Paper>
      </Grid>
    )}
      <Grid container spacing={3}>
      {technicianProgress.map((tech) => (
  <Grid item xs={12} sm={6} md={4} key={tech.technicianId} >
    <Box sx={{ width: '100%', mr: 10, ml: 2 }} >

      <ProgressBar name={tech.technicianName} mtdProgress={tech.mtdProgress} ytdProgress={tech.ytdProgress} />
    </Box>
  </Grid>
))}


  </Grid>
    </Grid>
    <div style={{ height: '100px' }}></div>
  </Container>
);
};

export default AdminDashboard;
