import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Container,
  Card,
  CardContent,
} from '@mui/material';
import { VictoryArea, VictoryLine, VictoryBar, VictoryStack, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';
import { VictoryPie, VictoryTooltip } from 'victory';
import BackButton from '../components/BackButton';

const DataDashboard = () => {
  const { user } = useAuth0();
  const [coolers, setCoolers] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [technicianPmEvents, setTechnicianPmEvents] = useState([]); // New state for storing technician's PM events
  const [servicedCoolersCount, setServicedCoolersCount] = useState(0);
  const [monthlyServicedCoolersCount, setMonthlyServicedCoolersCount] = useState(0); // For storing monthly serviced coolers count
  const [yearlyServicedCoolersCount, setYearlyServicedCoolersCount] = useState(0); // For storing yearly serviced coolers count
  const [yearlyGoal, setYearlyGoal] = useState(0); // State to store yearly goal
 const [monthlyGoal, setMonthlyGoal] = useState(0); // State to store monthly goal
  const animationDuration = 1000;

  const chartContainerStyle = {
  width: '100%', // Use 100% to fill the container or set a specific size
  maxWidth: '350px', // Set a maximum width for the charts
  margin: '0 auto', // This centers the chart in the Paper if it's smaller
};
  const buttonStyle = {
    backgroundColor: '#1B40A7',
    color: '#fff',
    height: '70px',
    fontFamily: 'NeueEinstellungBold',
    margin: '8px',
    width: 'calc(50% - 16px)',
    borderRadius: '8px'
  };

  useEffect(() => {
    if (user && user.sub) {
      axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/goals/${user.sub}`)
        .then(response => {
          const { yearly_goal, monthly_goal } = response.data;
          setYearlyGoal(yearly_goal);
          setMonthlyGoal(monthly_goal);
        })
        .catch(err => console.error('Error fetching goals', err));
    }
  }, [user]);

  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers')
      .then(response => setCoolers(response.data))
      .catch(err => console.error('Error fetching coolers', err));
  }, []);

  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/all-pm-events')
      .then(response => setServicedCoolersCount(response.data.length))
      .catch(err => console.error('Error fetching all PM events', err));
  }, []);

  useEffect(() => {
    const fetchMonthlyPmEventsForTechnician = async () => {
      if (user && user.sub) {
        try {
          const technicianAuth0Id = user.sub;
          const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-pm-report/${technicianAuth0Id}?filter=monthly`);
          const monthlyEvents = response.data;
          const monthlyServicedCoolers = monthlyEvents.reduce((total, event) => total + event.coolers_serviced.length, 0);
          setMonthlyServicedCoolersCount(monthlyServicedCoolers); // Update monthly serviced coolers count
        } catch (err) {
          console.error('Error fetching monthly PM events for technician', err);
        }
      }
    };

    fetchMonthlyPmEventsForTechnician();
  }, [user]);

  // useEffect for fetching yearly PM events
  useEffect(() => {
    const fetchYearlyPmEventsForTechnician = async () => {
      if (user && user.sub) {
        try {
          const technicianAuth0Id = user.sub;
          const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-pm-report/${technicianAuth0Id}?filter=yearly`);
          const yearlyEvents = response.data;
          const yearlyServicedCoolers = yearlyEvents.reduce((total, event) => total + event.coolers_serviced.length, 0);
          setYearlyServicedCoolersCount(yearlyServicedCoolers); // Update yearly serviced coolers count
        } catch (err) {
          console.error('Error fetching yearly PM events for technician', err);
        }
      }
    };

    fetchYearlyPmEventsForTechnician();
  }, [user]);


  const paperStyle = {
    padding: '1rem',
    borderRadius: '20px',
    //minHeight: '500px', // Adjust this value based on your requirement
    fontFamily: 'NeueEinstellungBold',
  };

  const totalCoolersByLocation = coolers.reduce((acc, cooler) => {
    acc[cooler.location] = (acc[cooler.location] || 0) + 1;
    return acc;
  }, {});

  const locationData = Object.entries(totalCoolersByLocation).map(([location, count]) => ({
    location,
    count,
  }));
  const servicedCoolersPercentage = yearlyGoal ? Math.round((yearlyServicedCoolersCount / yearlyGoal) * 100) : 0;
  const servicedCoolersPercentage1 = monthlyGoal ? Math.round((monthlyServicedCoolersCount / monthlyGoal) * 100) : 0;
  const assignedCoolersCount = coolers.reduce((acc, cooler) => cooler.AssignedTechnicianName ? acc + 1 : acc, 0);
  const totalCoolersCount = coolers.length;

  const pieChartData = [
    { x: "Serviced", y: yearlyServicedCoolersCount },
    { x: "Unserviced", y: yearlyGoal - yearlyServicedCoolersCount },
  ];

  const pieChartData1 = [
    { x: "Serviced", y: monthlyServicedCoolersCount },
    { x: "Unserviced", y: monthlyGoal - monthlyServicedCoolersCount },
  ];




  const cardStyle = {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'left',
   padding: '1rem',
   marginBottom: '5rem',
   borderRadius: '20px',
   boxShadow: '0px 6px 18px rgba(0,0,0,0.1)',
   backgroundColor: 'white',
 };

 const areaStyle = {
   data: {
     fill: "#1B40A7", // Area color
     fillOpacity: 0.7, // Semi-opaque
   },
 };

 const chartStyle = {
   parent: {

     boxShadow: "0px 6px 18px rgba(0,0,0,0.1)",
     borderRadius: "20px",
   },
 };

 return (
  <Container>
     <BackButton />
    <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar>
        <Typography variant="h8" style={{ paddingLeft: '0', flexGrow: 1, color: '#333', fontFamily: "Akira" }}>
          Welcome Back, {user.name.split(" ")[0]}
        </Typography>
      </Toolbar>
    </AppBar>


    {/* Charts Section, integrated with your data */}
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6}>
        <Paper elevation={0} style={{ ...paperStyle, maxWidth: '400px', margin: '0 auto' }}>
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold' }}>
            YTD Goal: {servicedCoolersPercentage}%
          </Typography>
          <VictoryPie
            data={pieChartData}
            colorScale={["#1B40A7", "#cccccc"]}
            labels={() => ''}
            width={300}
            height={300}
            style={{
              parent: {
                maxWidth: '400px',
              }
            }}
            animate={{
              duration: animationDuration,
              onLoad: { duration: animationDuration }
            }}
          />
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold' }}>
            {yearlyServicedCoolersCount} of {yearlyGoal} Coolers Serviced
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Paper elevation={0} style={{ ...paperStyle, maxWidth: '400px', margin: '0 auto' }}>
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold' }}>
            MTD Goal: {servicedCoolersPercentage1}%
          </Typography>
          <VictoryPie
            data={pieChartData1}
            colorScale={["#1B40A7", "#cccccc"]}
            labels={() => ''}
            width={300}
            height={300}
            style={{
              parent: {
                maxWidth: '400px',
              }
            }}
            animate={{
              duration: animationDuration,
              onLoad: { duration: animationDuration }
            }}
          />
          <Typography variant="h8" gutterBottom style={{ fontSize: 12,fontFamily: 'NeueEinstellungBold' }}>
            {monthlyServicedCoolersCount} of {monthlyGoal} Coolers Serviced
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  </Container>
);
};

export default DataDashboard;
