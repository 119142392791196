import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LogoutIcon from '@mui/icons-material/Logout';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import ScienceIcon from '@mui/icons-material/Science';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const Sidebar = ({ open, onClose }) => {
  const { logout, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [showTechReport, setShowTechReport] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const appBarHeight = isMobile ? '56px' : '64px';

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/get-user-role', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ userId: user.sub })
          });

          if (response.ok) {
            const data = await response.json();
            setShowTechReport(data.roles.includes('Admin'));
          }
        } catch (error) {
          console.error("Error fetching user role", error);
        }
      }
    };

    fetchUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    onClose();
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { marginTop: appBarHeight, boxShadow: 'none', borderRadius: '0px 10px 10px 0px' }
      }}
      ModalProps={{
        BackdropProps: {
          style: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <List>
        <ListItem button component={Link} to="/" onClick={onClose}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/technician" onClick={onClose}>
          <ListItemIcon>
            <EngineeringIcon />
          </ListItemIcon>
          <ListItemText primary="Route and Service" />
        </ListItem>
        <ListItem button component={Link} to="/datadashboard" onClick={onClose}>
          <ListItemIcon>
            <BubbleChartIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {showTechReport && (
          <>
            <ListItem button component={Link} to="/settings" onClick={onClose}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button component={Link} to="/tech-report" onClick={onClose}>
              <ListItemIcon><AssessmentIcon /></ListItemIcon>
              <ListItemText primary="Tech Report" />
            </ListItem>
            <ListItem button component={Link} to="/upload-coolers" onClick={onClose}>
              <ListItemIcon><UploadFileIcon /></ListItemIcon>
              <ListItemText primary="Upload Coolers" />
            </ListItem>

          </>
        )}
        <ListItem button component="a" onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
